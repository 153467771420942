import React from 'react'
import Logo from "../assets/img/logo.png"

export default function Nav() {
    return (
        <div className='w-full px-[7px]'>
            <div className='mids:pl-5 pl-1 mids:mt-7 mt-[54px] mids:mb-0 mb-[23px] mids:w-[137px] w-[121px] mids:h-[85px] h-[31px]'>
                <img src={Logo} alt='logo' className='w-full h-full object-cover' />
            </div>
            <div className='bg-hr h-[0.5px] w-full'></div>
        </div>
    )
}
