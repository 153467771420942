import ReactDOM from "react-dom";
import { ReactComponent as Exit } from "../assets/svg/exit.svg";

const portalRoot = document.getElementById("modal");

export default function ModalPop({ children, isOpen, closeModal }) {
    if (!isOpen) return null;
    
    return ReactDOM.createPortal(
        <div
            onClick={() => {
                closeModal && closeModal();
            }}
            className={`fixed w-full h-screen top-0 mx-auto items-center flex z-50 justify-center overflow-hidden`}
        >
            <div className="mids:px-[54px] px-6 mids:py-[47px] py-[57px] bg-[#181818] mids:rounded-[27px]">
                <div className="w-full flex justify-end">
                    <Exit className="cursor-pointer" onClick={() => {
                        closeModal && closeModal();
                    }}/>
                </div>
                {children}
            </div>
        </div>,
        portalRoot
    );
}
