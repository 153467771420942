import React from 'react'
import { CgSpinner } from 'react-icons/cg';

export default function Button({ text, full, functions, loading }) {
    return (
        <button
            onClick={functions} 
            className={`rounded-[22.5px] flex justify-center items-center text-white text-[20px] font-medium w-[184px] h-[45px] ${full ? 'bg-[#1A76E7]' : 'border border-[#1A76E7] bg-transparent'}`}
        >
            {loading ? <CgSpinner className="animate-spin text-lg"/> : <p>
                {text}
            </p>}
        </button>
    )
}
