import React from 'react'
import Stick from './Stick'
import Content1 from '../assets/img/content.png'
import Content2 from '../assets/img/content2.png'
import { ReactComponent as Ellipse1 } from '../assets/svg/Ellipse 50.svg'
import Ellipse2 from '../assets/img/Ellipse 49.png'
import Ellipse3 from '../assets/img/Ellipse 51.png' 
import Ellipse4 from '../assets/img/Ellipse 56.png' 
import Ellipse5 from '../assets/img/Ellipse 54.png' 
import Ellipse6 from '../assets/img/Ellipse 55.png' 
import Ellipse7 from '../assets/img/Ellipse 57.png' 

export default function About() {
    return (
        <div className='hidden mids:flex w-full justify-center h-[728px] gap-[42px] px-5'>
            <div>
                <Stick height={'h-[131.1px]'} ball={true}/>
                <img src={Content1} alt='about' className='rounded-[37px] h-[354px] w-[316.9px]'/>
                <Stick height={'h-[195.9px]'} reverse={true} ball={true}/>
            </div>

            <div className='flex flex-col justify-end'>
                <div className='flex gap-3'>
                    <Ellipse1 />
                    <Stick height={'h-[131.1px]'} ball={true}/>
                </div>
                <div className='bg-[#00CEEA] rounded-[26px] w-[142px] h-[159px] flex justify-center items-center'>
                    <p className='text-[20px] font-normal text-[#2E2E2E]'>Blast into 
                    <br />the music
                    <br />scene
                    </p>
                </div>
                <div className='flex gap-9 justify-end'>
                    <Stick height={'h-[313.9px]'} reverse={true}/>
                    <img src={Ellipse2} alt='content' className='w-[43px] h-[43px] object-cover mt-9' />
                </div>
            </div>
            
            <div className='-ml-[22px] flex gap-[38px]'>
                <div className='flex flex-col gap-[66px] justify-end h-[370px] items-center'>
                    <img src={Ellipse3} alt='content' className='w-[43px] h-[43px] object-cover' />
                    <img src={Ellipse4} alt='content' className='w-[25px] h-[25px] object-cover' />
                </div>
                <div className='flex flex-col justify-end'>
                    <Stick height={'h-[180px]'} ball={true}/>
                    <img src={Ellipse5} alt='content' className='w-[43px] h-[43px] object-cover' />
                    <Stick height={'h-[311px]'} reverse={true}/>
                </div>
            </div>
            
            <div className='-ml-[22px] h-[460px] self-center flex flex-col'>
                <div className='flex flex-col items-end mr-10'>
                    <Stick height={'h-[26px]'} ball={true}/>
                </div>
                <div className='bg-[#575855] h-[50px] w-[185px] rounded-[17px] flex justify-center items-center'>
                    <p className='text-[20px] font-normal text-white'>With your fans</p>
                </div>
                <div className='flex flex-col items-end mr-11'>
                    <Stick height={'h-[45px]'} />
                </div>
                <div className='flex gap-[13px]'>
                    <div className='flex flex-col gap-[85px] items-end justify-end h-[190px]'>
                        <img src={Ellipse6} alt='content' className='w-[43px] h-[43px] object-cover' />
                        <img src={Ellipse7} alt='content' className='w-[25px] h-[25px] object-cover' />
                    </div>
                    <div className='bg-[#B3FFB6] rounded-[26px] w-[142px] h-[159px] flex justify-center items-center'>
                        <p className='text-[20px] font-normal text-[#2E2E2E]'>Host your
                        <br />listening &
                        <br />release
                        <br />parties
                        </p>
                    </div>
                </div>
            </div>
            
            <div>
                <Stick height={'h-[46px]'} ball={true}/>
                <img src={Content2} alt='about' className='rounded-[35px] h-[314px] w-[284px]'/>
            </div>
        </div>
    )
}
