import React from 'react'
import Stick from './Stick'
import Content1 from '../assets/img/content.png'
import Content2 from '../assets/img/content2.png'
import { ReactComponent as Ellipse1 } from '../assets/svg/Ellipse 50.svg'
import Ellipse2 from '../assets/img/Ellipse 49.png'
import Ellipse3 from '../assets/img/Ellipse 51.png' 
import Ellipse4 from '../assets/img/Ellipse 56.png' 
import Ellipse5 from '../assets/img/Ellipse 54.png' 
import Ellipse6 from '../assets/img/Ellipse 55.png' 
import Ellipse7 from '../assets/img/Ellipse 57.png' 

export default function About2() {
    return (
        <div className='mids:hidden flex flex-col w-full justify-center items-center px-[17px]'>
            <div className='flex gap-[27px] w-full justify-between'>
                <div className='w-full flex flex-col items-start'>
                    <div className='ml-10'>
                        <Stick height={'h-14'} ball={true}/>
                    </div>
                    <img src={Content1} alt='about' className='rounded-[23px] object-cover tab:h-[50%] h-[253px] w-full'/>
                    <div className='ml-10'>
                        <Stick height={'h-[42px]'} reverse={true} />
                    </div>
                    <div className='bg-[#B3FFB6] rounded-[26px] w-[142px] h-[159px] flex justify-center items-center'>
                        <p className='text-[20px] font-normal text-[#2E2E2E]'>Host your
                        <br />listening &
                        <br />release
                        <br />parties
                        </p>
                    </div>
                    <div className='ml-10'>
                        <Stick height={'h-[200px]'} reverse={true} ball={true} />
                    </div>
                </div>

                <div className='-mt-[26px] flex flex-col w-full items-end'>
                    <div className='w-full flex justify-end mr-7'>
                        <Stick height={'h-20'} ball={true}/>
                    </div>
                    <div className='bg-[#00CEEA] rounded-[26px] w-[142px] h-[159px] flex justify-center items-center'>
                        <p className='text-[20px] font-normal text-[#2E2E2E]'>Blast into 
                        <br />the music
                        <br />scene
                        </p>
                    </div>
                    <div className='flex flex-col w-full items-end'>
                        <Ellipse1 className='mt-[34px] mb-[35px]' />
                        <img src={Ellipse6} alt='content' className='w-[43px] h-[43px] object-cover mr-20 mb-[30px]' />
                        <img src={Ellipse3} alt='content' className='w-[43px] h-[43px] object-cover mb-2' />
                        <img src={Ellipse5} alt='content' className='w-[43px] h-[43px] object-cover mr-20 mb-[30px]' />
                        <img src={Ellipse7} alt='content' className='w-[43px] h-[43px] object-cover mb-2' />
                        <img src={Ellipse2} alt='content' className='w-[43px] h-[43px] object-cover mr-20' />
                    </div>
                </div>
            </div>
            
            <div className='-mt-[350px] self-center flex flex-col'>
                <div className='flex flex-col '>
                    <Stick height={'h-[290px]'} ball={true}/>
                </div>
                <div className='bg-[#575855] h-[50px] w-[185px] rounded-[17px] flex justify-center items-center'>
                    <p className='text-[20px] font-normal text-white'>With your fans</p>
                </div>
                <div className='flex flex-col'>
                    <Stick height={'h-[45px]'} />
                </div>
            </div>
            
            <div>
                <img src={Content2} alt='about' className='rounded-[35px] h-[314px] w-[284px]'/>
            </div>
        </div>
    )
}
