import React from 'react'

export default function Stick({ height, reverse, ball }) {
    return (
        <div className={`flex ${reverse ? 'flex-col-reverse' : 'flex-col'} items-center justify-center ${height}`}>
            {ball && <div className='w-2 h-2 rounded-lg bg-grey-300'></div>}
            <div className='w-[1.5px] bg-grey-300 h-full'></div>
        </div>
    )
}
