import React, { useState } from 'react'
import axios from 'axios';
import Button from './Button';
import About from './About';
import ModalPop from './Modal';
import About2 from './About2';
import { useSnackbar } from 'notistack';

export default function Landing() {
    const { enqueueSnackbar } = useSnackbar()
    const [init, setInit] = useState(true);
    const [learn, setLearn] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)

    const subscribe = async () => {
        if (!email) {
            return;
        }
        
        setLoading(true);
        setEmail('');

        const data = {
            email: email
        }

        try {
            const res = await axios.post('https://onvail.onrender.com/waitlists/add-to-waitlist', data);
            console.log(res);
            setLoading(false);
            setInit(false);
        } catch (error){
            setLoading(false)
            enqueueSnackbar(`${error.response.data.message}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            console.error(error, 'error subscribing')
        }
    }

    return (
        <div className='flex-1 flex flex-col items-center justify-center mids:pt-[60px] pt-[55px] px-[7px]'>
            <h1 className='hidden mids:block text-[80px] font-bold text-center leading-[90px] mb-[53px] '>Boost your visibility as 
            <br />an emerging artist</h1>
            <h1 className={`${init ? 'block' : 'hidden'} mids:hidden text-[60px] font-bold text-center leading-[70px]`}>Boost your visibility </h1>
            <span className={`${init ? 'block' : 'hidden'} mids:hidden text-[25px] font-normal text-[#1A76E7] mb-[39px]`}>
                as an emerging artist
            </span>
            {init ? (
                <div className='w-full flex flex-col items-center'>
                    <input 
                        className='mb-[31px] px-[18px] py-3 focus:outline-0 rounded-[4px] mids:w-[548px] w-[304px] mids:h-[47.9px] h-[41px] border border-white bg-grey-100 text-[16px] mids:text-[17px] font-normal text-grey-200'
                        placeholder='Email address'
                        type='email'
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className='mids:flex hidden gap-[19px] w-full justify-center'>
                        <Button text={'Join Waitlist'} full={true} functions={() => subscribe()} loading={loading} />
                        <Button text={'Learn More'} functions={() => setLearn(true)}/>
                    </div>
                    <div className='flex flex-col items-center mids:hidden gap-5 w-full justify-center'>
                        <Button text={'Join Waitlist'} full={true} functions={() => subscribe()} loading={loading} />
                        <Button text={'Learn More'} functions={() => setLearn(true)}/>
                    </div>
                </div>
            ) : (
                <div className='w-full flex pt-[62px] mids:pt-0 flex-col items-center gap-[14px] mids:gap-0 px-8 mids:px-0 mb-[115px] mids:mb-0'>
                    <p className='mids:text-[34px] text-[32px] mids:font-medium font-bold text-center'>Thank you for joining the Onvail Community</p>
                    <p className='mids:text-[30px] text-[21px] mids:font-normal font-bold text-center'>You will receive a notification from us shortly</p>
                </div>
            )}
            <div className='mt-[82px] w-full '>
                <h2 className='text-center items-center gap-[10px] h-9 mid:text-[30px] text-[20px] font-light flex justify-center'>
                    Create 
                    <span className='rounded-lg w-2 h-2 bg-white inline-block'></span> 
                    Host 
                    <span className='rounded-lg w-2 h-2 bg-white inline-block'></span> 
                    Engage
                </h2>
            </div>
            <About />
            <About2 />
            <ModalPop isOpen={learn} closeModal={() => setLearn(false)}>
                <p className='mids:text-[20px] text-[12px] font-medium mids:w-[1000px] w-full mids:leading-[66px] leading-[44px]'>
                    "Onvail" is primarily an AI and data-driven social networking and scouting service that incubates talents in the arts, 
                    encouraging them to unveil their creative work with fans and relevant industry professionals while empowering artists 
                    financially. Onvail is dedicated to facilitating the growth and development of emerging artists. 
                    Currently, we are concentrating on developing the musical aspect of the platform. Thus, "Onvail" is presently an app idea 
                    aimed at providing a platform to connect music lovers (the public), music creators (artists, producers, etc.), 
                    and music professionals (artists and repertoire [A&Rs], etc.) with each other.
                </p>
            </ModalPop>
        </div>
    )
}
